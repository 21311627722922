import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { oc } from 'ts-optchain';
import { hasAmbTrips } from '../../../../shared/classes/utils/utils';
import { LoggerService, Signature } from '../../../../shared/sdk';
import { DIRECTION_MARKERS, SERVICE_TYPE } from '../../../trip-manifest/classes/enums';

@Component({
  selector: 'app-dlg-edit-service-type',
  templateUrl: './dlg-edit-service-type.component.html',
  styleUrls: ['./dlg-edit-service-type.component.scss'],
})
export class DlgEditServiceTypeComponent {
  serviceTypes = [...(hasAmbTrips() ? ['AMB_TRIP'] : []), ...Object.values(SERVICE_TYPE)];
  markers = [...(hasAmbTrips() ? ['TO_AMB', 'FROM_AMB'] : []), ...Object.values(DIRECTION_MARKERS)];
  value: { serviceType: string; marker: string };

  constructor(
    private dialogRef: MatDialogRef<DlgEditServiceTypeComponent, any>,
    @Inject(MAT_DIALOG_DATA)
    private data: {
      signature?: Signature;
    },
    protected logger: LoggerService,
  ) {
    this.value = {
      serviceType: oc(data).signature.vServiceType(),
      marker: oc(data).signature.marker(),
    };
  }
}
