import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { DataSourceOptions } from 'devextreme/data/data_source';
import identity from 'lodash-es/identity';
import isEmpty from 'lodash-es/isEmpty';
import { utc } from 'moment';
import moment from 'moment/moment';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { gqlMongoStore } from '../../../../shared/classes/loopback-custom-store/generic/store.utils';
import { discretizeMonths } from '../../../../shared/classes/utils/time.utils';
import { hAll } from '../../../../shared/classes/utils/utils';
import { CommonService } from '../../../../shared/modules/my-common/services/common.service';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { PusherService } from '../../../../shared/modules/my-common/services/pusher.service';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { GridHelperService } from '../../../../shared/modules/ui/services/grid-helper.service';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import { Facility, LoggerService } from '../../../../shared/sdk';
import { DlgNotifyCoordComponent } from '../../dialogs/dlg-notify-coord/dlg-notify-coord.component';
import { AuthClaimService } from '../../services/auth-claim.service';

@Component({
  selector: 'app-expired-authorizations',
  templateUrl: './expired-authorizations.component.html',
  styleUrls: ['./expired-authorizations.component.scss'],
  providers: [AuthClaimService],
})
export class ExpiredAuthorizationsComponent extends ABaseComponent implements OnInit {
  @ViewChild('grid', { static: true }) grid: DxDataGridComponent;
  grid_stateStoring: any;

  dso: DataSourceOptions = [];
  facilityDso: DataSourceOptions = [];
  groupedFacilityDso: DataSourceOptions = [];
  selectedFacilityId: number;

  byMemberDso = new Map<string, any>();

  statusDso = ['ALL', 'ACTIVE', 'INACTIVE', 'PENDING', 'ON_HOLD', 'TO_PROCESS'];
  selectedClientStatus: 'ALL' | 'ACTIVE' | 'INACTIVE' | 'PENDING' | 'ON_HOLD' | 'TO_PROCESS' = 'ACTIVE';

  codeDso: any;
  selectedCode: string;

  expireDso = ['Missing Now', 'Missing Next Month'];
  selectedFilter: 'Missing Now' | 'Missing Next Month';

  fromInclMoment = moment().startOf('month').subtract(6, 'months');
  toExclMoment = moment()
    .startOf('month')
    .add(1 + 1, 'months');
  months = discretizeMonths(this.fromInclMoment, this.toExclMoment, 'YYYY-MM-DD');

  $filterEvent$: BehaviorSubject<any> = new BehaviorSubject<any>(false);

  constructor(
    public logger: LoggerService,
    public config: ConfigService,
    private ui: UiService,
    private dialog: MatDialog,
    private dss: DataSourceService,
    public common: CommonService,
    public service: AuthClaimService,
    private pusher: PusherService,
    private gridHelper: GridHelperService,
  ) {
    super(logger);

    // this.grid_stateStoring = {
    //   enabled: true,
    //   type: 'localStorage',
    //   storageKey: '91338a52-dc87-4860-a136-f6ad5e6d54a8',
    // };
  }

  ngOnInit() {
    super.ngOnInit();

    this.groupedFacilityDso = this.service.buildFacilityDataSource(['BASE', 'ADC', 'MEALS', 'TC'], true);
    this.facilityDso = this.service.buildFacilityDataSource(['BASE', 'ADC', 'MEALS', 'TC'], false);
    this.codeDso = this.service.buildCodeDso(this.dss);

    this.$filterEvent$
      .pipe(
        filter(identity),
        tap(() => {
          // this.ui.showLoading();
          this.grid.instance.beginCustomLoading('Building Data Source...');
          this.dso = [];
        }),
        switchMap(() => this.buildDataSource$()),
        tap(dso => (this.dso = dso)),
        tap(() => {
          // this.ui.hideLoading();
          this.grid.instance.endCustomLoading();
        }),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();
  }

  grid_onInitialized(e) {
    this.gridHelper.handle(e.component, {
      flatToTreeObject: false,
      copyIdsOnSaving: false,
      selectRowOnEdit: false,
      notifyErrors: true,
    });
  }

  grid_onToolbarPreparing(e) {}

  grid_onContextMenuPreparing(e) {}

  grid_onCellPrepared(e) {
    if (e.rowType === 'data') {
      const curMonth = moment().startOf('month').format('YYYY-MM');
      const nextMonth = moment().startOf('month').add(1, 'month').format('YYYY-MM');
      // const next2Month = moment().startOf('month').add(2, 'month').format('YYYY-MM');
      // const next3Month = moment().startOf('month').add(3, 'month').format('YYYY-MM');

      if (
        (this.selectedFilter === 'Missing Now' && e.column.caption === curMonth) ||
        (this.selectedFilter === 'Missing Next Month' && e.column.caption === nextMonth)
        // || (this.selectedFilter === 'Missing Next 2 Months' && [nextMonth, next2Month].includes(e.column.caption))
        // || (this.selectedFilter === 'Missing Next 3 Months' && [nextMonth, next2Month, next3Month].includes(e.column.caption))
      ) {
        (e.cellElement as HTMLElement).style.backgroundColor = 'rgba(255,183,0,0.2)';
      }
    }
  }

  private buildDataSource$() {
    const self = this;
    const AuthCol = 'ExportsAuthsDataCache';

    return combineLatest([
      this.config.tenantType$,
      // this.selectedClientStatus === 'ACTIVE' ? this.dss.getApi<ConsumerApi>(Consumer).setStatusToAuths(hAll) : of(null),
      this.pusher.rpc<string>(
        'BUILD_CONSUMER_COLLECTION',
        {
          where: {
            ...(this.selectedClientStatus !== 'ALL' && !isEmpty(this.selectedClientStatus)
              ? { status: this.selectedClientStatus }
              : {}),

            ...(this.selectedFacilityId ? { tenantId: this.selectedFacilityId } : {}),
          },
          useRunService: true,
        },
        true,
        hAll,
      ),
    ]).pipe(
      switchMap(async ([tenantType, collection]) => {
        const curMonth = Number(moment().startOf('month').format('YYYYMM'));
        const nextMonth = Number(moment().startOf('month').add(1, 'month').format('YYYYMM'));
        // const next2Month = Number(moment().startOf('month').add(2, 'month').format('YYYYMM'));
        // const next3Month = Number(moment().startOf('month').add(3, 'month').format('YYYYMM'));

        const aggregate = [
          {
            $project: {
              id: 1,
              tenantId: 1,
              status: 1,
              mci: 1,
              activeMco: 1,
              cwName: 1,
              cwPhone: 1,
              cwFax: 1,
              cwEmail: 1,
              cwNote: 1,
              'person.firstname': 1,
              'person.lastname': 1,
              'person.dob': 1,
            },
          },
          //
          {
            $lookup: {
              from: 'LastAuthByMemberAndCode_view',
              localField: 'mci',
              foreignField: '_id.mci',
              pipeline: [
                {
                  $match: {
                    ...(this.selectedCode ? { '_id.Code': this.selectedCode } : {}),
                    ...(tenantType === 'BASE' ? {} : { 'last._tenantId': this.common.auth.getCurrentTenant() }),
                  },
                },
                {
                  $project: { 'last._source': 0 },
                },
              ],
              as: '__lastCodeAuth',
            },
          },
          {
            $unwind: {
              path: '$__lastCodeAuth',
              preserveNullAndEmptyArrays: true,
            },
          },
          //
          {
            $lookup: {
              from: AuthCol,
              localField: 'mci',
              foreignField: '_mci',
              let: { lastCode: '$__lastCodeAuth._id.Code' },
              pipeline: [
                {
                  $match: {
                    $expr: { $and: [{ $eq: ['$Code', '$$lastCode'] }] },
                    _inactive: { $ne: true },
                    _valid: true,

                    StartDT: {
                      $lt: { $date: { v: this.toExclMoment.toDate().toISOString() } },
                    },
                    EndDT: {
                      $gte: { $date: { v: this.fromInclMoment.toDate().toISOString() } },
                    },
                    ...(this.selectedCode ? { Code: this.selectedCode } : {}),
                    ...(tenantType === 'BASE' ? {} : { _tenantId: this.common.auth.getCurrentTenant() }),
                    // ...(this.selectedClientStatus === 'ACTIVE' ? { __status: 'ACTIVE' } : {}),
                  },
                },
                {
                  $project: { _source: 0 },
                },
                // {
                //   $sort: {
                //     StartDT: -1,
                //     _ctime: -1,
                //   },
                // },
              ],
              as: '__auth',
            },
          },
          {
            $unwind: {
              path: '$__auth',
              preserveNullAndEmptyArrays: true,
            },
          },
          {
            $unwind: {
              path: '$__auth._months',
              preserveNullAndEmptyArrays: true,
            },
          },
          //
          {
            $group: {
              _id: {
                mci: '$mci',
                Code: '$__lastCodeAuth._id.Code',
              },
              last: {
                $last: '$$CURRENT',
              },
              months: {
                $push: '$$CURRENT.__auth._months',
              },
            },
          },
          {
            $project: {
              months: 1,
              last: 1,
              curMonth: { $in: [curMonth, '$months'] },
              nextMonth: { $in: [nextMonth, '$months'] },
              // next2Month: { $in: [next2Month, '$months'] },
              // next3Month: { $in: [next3Month, '$months'] },
              ...this.months
                .map(m => utc(m).format('YYYYMM'))
                .map(m => ({ [m]: { $in: [Number(m), '$months'] } }))
                .reduce((acc, cur) => ({ ...acc, ...cur }), {}),
            },
          },
          {
            $match: {
              ...(this.selectedCode
                ? { $or: [{ '_id.Code': this.selectedCode }, { '_id.Code': { $exists: false } }] }
                : {}),

              ...(this.selectedFilter === 'Missing Now' ? { curMonth: false } : {}),
              ...(this.selectedFilter === 'Missing Next Month' ? { nextMonth: false } : {}),

              // ...(this.selectedFilter === 'Missing Next 2 Months'
              //   ? { $or: [{ nextMonth: false }, { next2Month: false }] }
              //   : {}),

              // ...(this.selectedFilter === 'Missing Next 3 Months'
              //   ? { $or: [{ nextMonth: false }, { next2Month: false }, { next3Month: false }] }
              //   : {}),
            },
          },
          // {
          //   $sort: {
          //     'last.__auth.StartDT': -1,
          //     'last.__auth._ctime': -1,
          //   },
          // },
        ];

        const store = gqlMongoStore(self.dss, collection, aggregate);
        return { store } as DataSourceOptions;
      }),
    );
  }

  getMemberDso(clientDoc) {
    const [mci, code] = [clientDoc._id.mci, clientDoc._id.Code];
    const dsoKey = [mci, code].join(':');
    const col = 'ExportsAuthsDataCache';

    if (!this.byMemberDso.has(dsoKey)) {
      const aggregate = [
        {
          $match: {
            _inactive: { $ne: true },
            _valid: true,
            _mci: mci,
            Code: code,
          },
        },
        {
          $sort: {
            StartDT: -1,
            _ctime: -1,
          },
        },
      ];

      const store = gqlMongoStore(this.dss, col, aggregate);
      const dso: DataSourceOptions = { store } as DataSourceOptions;
      this.byMemberDso.set(dsoKey, dso);
    }

    return this.byMemberDso.get(dsoKey);
  }

  filter() {
    this.$filterEvent$.next(true);
  }

  monthCustomizeTextFn(cellInfo) {
    return cellInfo.value ? 'Y' : '';
  }

  notifyCoordinator(e, cellInfo) {
    void this.dialog
      .open(DlgNotifyCoordComponent, {
        minWidth: '600px',
        hasBackdrop: true,
        data: { cellInfo },
      })
      .afterClosed()
      .toPromise();
  }
}
