import {
  Facility,
  Person,
  Program,
  ConsumerEmPerson,
  Document,
  Signature,
  Covid19Checks,
  Note,
  PersDevice,
  PersAlert
} from '../index';

declare var Object: any;
export interface ConsumerInterface {
  "status": string;
  "internalID"?: string;
  "mci"?: string;
  "autoDispatch"?: boolean;
  "keepStretcher"?: boolean;
  "onBoardingDuration"?: number;
  "offBoardingDuration"?: number;
  "source"?: string;
  "facilityID"?: string;
  "defaultDestination"?: string;
  "daysNotes"?: string;
  "specialInstrs"?: string;
  "transpInstrs"?: string;
  "transpCodes"?: any;
  "data"?: any;
  "eligibility"?: any;
  "activeMco"?: string;
  "activeBroker"?: string;
  "lastEligibility"?: string;
  "lastEligibilityDate"?: any;
  "clientSince"?: any;
  "repetitiveMode"?: string;
  "weekDays"?: any;
  "mealMeta"?: any;
  "preferredEmployees"?: any;
  "avoidEmployees"?: any;
  "signImgFileId"?: string;
  "profileImgFileId"?: string;
  "notes"?: string;
  "brokerNotes"?: string;
  "c10nAgency"?: string;
  "cwName"?: string;
  "cwPhone"?: string;
  "cwFax"?: string;
  "cwEmail"?: string;
  "cwNote"?: string;
  "legacyCustomerID"?: string;
  "tcInformedConsentDocumentSignatureDate"?: any;
  "tcInformedConsentFile"?: string;
  "persLastSuccessfulTest"?: Date;
  "persAlertsLast30D"?: number;
  "persAlertsLast90D"?: number;
  "persAlertsLast12M"?: number;
  "persNotes"?: string;
  "hash1"?: string;
  "hash2"?: string;
  "ctime"?: Date;
  "utime"?: Date;
  "dtime"?: Date;
  "id"?: number;
  "tenantId"?: number;
  "personId"?: number;
  "programId"?: number;
  tenant?: Facility;
  person?: Person;
  program?: Program;
  emRelations?: ConsumerEmPerson[];
  emPeople?: Person[];
  relatedDocuments?: Document[];
  signatures?: Signature[];
  covid19Checks?: Covid19Checks[];
  relatedNotes?: Note[];
  persDevices?: PersDevice[];
  persAlerts?: PersAlert[];
}

export class Consumer implements ConsumerInterface {
  "status": string;
  "internalID": string;
  "mci": string;
  "autoDispatch": boolean;
  "keepStretcher": boolean;
  "onBoardingDuration": number;
  "offBoardingDuration": number;
  "source": string;
  "facilityID": string;
  "defaultDestination": string;
  "daysNotes": string;
  "specialInstrs": string;
  "transpInstrs": string;
  "transpCodes": any;
  "data": any;
  "eligibility": any;
  "activeMco": string;
  "activeBroker": string;
  "lastEligibility": string;
  "lastEligibilityDate": any;
  "clientSince": any;
  "repetitiveMode": string;
  "weekDays": any;
  "mealMeta": any;
  "preferredEmployees": any;
  "avoidEmployees": any;
  "signImgFileId": string;
  "profileImgFileId": string;
  "notes": string;
  "brokerNotes": string;
  "c10nAgency": string;
  "cwName": string;
  "cwPhone": string;
  "cwFax": string;
  "cwEmail": string;
  "cwNote": string;
  "legacyCustomerID": string;
  "tcInformedConsentDocumentSignatureDate": any;
  "tcInformedConsentFile": string;
  "persLastSuccessfulTest": Date;
  "persAlertsLast30D": number;
  "persAlertsLast90D": number;
  "persAlertsLast12M": number;
  "persNotes": string;
  "hash1": string;
  "hash2": string;
  "ctime": Date;
  "utime": Date;
  "dtime": Date;
  "id": number;
  "tenantId": number;
  "personId": number;
  "programId": number;
  tenant: Facility;
  person: Person;
  program: Program;
  emRelations: ConsumerEmPerson[];
  emPeople: Person[];
  relatedDocuments: Document[];
  signatures: Signature[];
  covid19Checks: Covid19Checks[];
  relatedNotes: Note[];
  persDevices: PersDevice[];
  persAlerts: PersAlert[];
  constructor(data?: ConsumerInterface) {
    Object.assign(this, data);
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `Consumer`.
   */
  public static getModelName() {
    return "Consumer";
  }

  /**
  * This method creates an instance of Consumer for dynamic purposes.
  **/
  public static factory(data: ConsumerInterface): Consumer{
    return new Consumer(data);
  }

  /**
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Consumer',
      plural: 'Consumers',
      path: 'Consumers',
      idName: 'id',
      properties: {
        "status": {
          name: 'status',
          type: 'string'
        },
        "internalID": {
          name: 'internalID',
          type: 'string'
        },
        "mci": {
          name: 'mci',
          type: 'string'
        },
        "autoDispatch": {
          name: 'autoDispatch',
          type: 'boolean'
        },
        "keepStretcher": {
          name: 'keepStretcher',
          type: 'boolean'
        },
        "onBoardingDuration": {
          name: 'onBoardingDuration',
          type: 'number'
        },
        "offBoardingDuration": {
          name: 'offBoardingDuration',
          type: 'number'
        },
        "source": {
          name: 'source',
          type: 'string'
        },
        "facilityID": {
          name: 'facilityID',
          type: 'string'
        },
        "defaultDestination": {
          name: 'defaultDestination',
          type: 'string'
        },
        "daysNotes": {
          name: 'daysNotes',
          type: 'string'
        },
        "specialInstrs": {
          name: 'specialInstrs',
          type: 'string'
        },
        "transpInstrs": {
          name: 'transpInstrs',
          type: 'string'
        },
        "transpCodes": {
          name: 'transpCodes',
          type: 'any',
          default: <any>null
        },
        "data": {
          name: 'data',
          type: 'any',
          default: <any>null
        },
        "eligibility": {
          name: 'eligibility',
          type: 'any',
          default: <any>null
        },
        "activeMco": {
          name: 'activeMco',
          type: 'string'
        },
        "activeBroker": {
          name: 'activeBroker',
          type: 'string'
        },
        "lastEligibility": {
          name: 'lastEligibility',
          type: 'string'
        },
        "lastEligibilityDate": {
          name: 'lastEligibilityDate',
          type: 'any'
        },
        "clientSince": {
          name: 'clientSince',
          type: 'any'
        },
        "repetitiveMode": {
          name: 'repetitiveMode',
          type: 'string'
        },
        "weekDays": {
          name: 'weekDays',
          type: 'any',
          default: <any>null
        },
        "mealMeta": {
          name: 'mealMeta',
          type: 'any',
          default: <any>null
        },
        "preferredEmployees": {
          name: 'preferredEmployees',
          type: 'any',
          default: <any>null
        },
        "avoidEmployees": {
          name: 'avoidEmployees',
          type: 'any',
          default: <any>null
        },
        "signImgFileId": {
          name: 'signImgFileId',
          type: 'string'
        },
        "profileImgFileId": {
          name: 'profileImgFileId',
          type: 'string'
        },
        "notes": {
          name: 'notes',
          type: 'string'
        },
        "brokerNotes": {
          name: 'brokerNotes',
          type: 'string'
        },
        "c10nAgency": {
          name: 'c10nAgency',
          type: 'string'
        },
        "cwName": {
          name: 'cwName',
          type: 'string'
        },
        "cwPhone": {
          name: 'cwPhone',
          type: 'string'
        },
        "cwFax": {
          name: 'cwFax',
          type: 'string'
        },
        "cwEmail": {
          name: 'cwEmail',
          type: 'string'
        },
        "cwNote": {
          name: 'cwNote',
          type: 'string'
        },
        "legacyCustomerID": {
          name: 'legacyCustomerID',
          type: 'string'
        },
        "tcInformedConsentDocumentSignatureDate": {
          name: 'tcInformedConsentDocumentSignatureDate',
          type: 'any'
        },
        "tcInformedConsentFile": {
          name: 'tcInformedConsentFile',
          type: 'string'
        },
        "persLastSuccessfulTest": {
          name: 'persLastSuccessfulTest',
          type: 'Date'
        },
        "persAlertsLast30D": {
          name: 'persAlertsLast30D',
          type: 'number'
        },
        "persAlertsLast90D": {
          name: 'persAlertsLast90D',
          type: 'number'
        },
        "persAlertsLast12M": {
          name: 'persAlertsLast12M',
          type: 'number'
        },
        "persNotes": {
          name: 'persNotes',
          type: 'string'
        },
        "hash1": {
          name: 'hash1',
          type: 'string'
        },
        "hash2": {
          name: 'hash2',
          type: 'string'
        },
        "ctime": {
          name: 'ctime',
          type: 'Date'
        },
        "utime": {
          name: 'utime',
          type: 'Date'
        },
        "dtime": {
          name: 'dtime',
          type: 'Date'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "tenantId": {
          name: 'tenantId',
          type: 'number'
        },
        "personId": {
          name: 'personId',
          type: 'number'
        },
        "programId": {
          name: 'programId',
          type: 'number'
        },
      },
      relations: {
        tenant: {
          name: 'tenant',
          type: 'Facility',
          model: 'Facility',
          relationType: 'belongsTo',
                  keyFrom: 'tenantId',
          keyTo: 'id'
        },
        person: {
          name: 'person',
          type: 'Person',
          model: 'Person',
          relationType: 'belongsTo',
                  keyFrom: 'personId',
          keyTo: 'id'
        },
        program: {
          name: 'program',
          type: 'Program',
          model: 'Program',
          relationType: 'belongsTo',
                  keyFrom: 'programId',
          keyTo: 'id'
        },
        emRelations: {
          name: 'emRelations',
          type: 'ConsumerEmPerson[]',
          model: 'ConsumerEmPerson',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'consumerId'
        },
        emPeople: {
          name: 'emPeople',
          type: 'Person[]',
          model: 'Person',
          relationType: 'hasMany',
          modelThrough: 'ConsumerEmPerson',
          keyThrough: 'personId',
          keyFrom: 'id',
          keyTo: 'consumerId'
        },
        relatedDocuments: {
          name: 'relatedDocuments',
          type: 'Document[]',
          model: 'Document',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'objectId'
        },
        signatures: {
          name: 'signatures',
          type: 'Signature[]',
          model: 'Signature',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'consumerId'
        },
        covid19Checks: {
          name: 'covid19Checks',
          type: 'Covid19Checks[]',
          model: 'Covid19Checks',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'consumerId'
        },
        relatedNotes: {
          name: 'relatedNotes',
          type: 'Note[]',
          model: 'Note',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'consumerId'
        },
        persDevices: {
          name: 'persDevices',
          type: 'PersDevice[]',
          model: 'PersDevice',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'consumerId'
        },
        persAlerts: {
          name: 'persAlerts',
          type: 'PersAlert[]',
          model: 'PersAlert',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'consumerId'
        },
      }
    }
  }
}
