<div mat-dialog-content>
  <div class="dx-fieldset">
    <div class="dx-field">
      <label>Origin:</label>
        <dx-select-box
          [(ngModel)]="value.o"
          [dataSource]="destDs$ | async"
          [grouped]="true"
          [displayExpr]="'Name'"
          [valueExpr]="'ID'"
          [searchEnabled]="true"
        ></dx-select-box>
    </div>

    <div class="dx-field">
      <label>Destination:</label>
        <dx-select-box
          [(ngModel)]="value.d"
          [dataSource]="destDs$ | async"
          [grouped]="true"
          [displayExpr]="'Name'"
          [valueExpr]="'ID'"
          [searchEnabled]="true"
        ></dx-select-box>
    </div>
  </div>
</div>

<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="false">Cancel</button>
  <button mat-button [mat-dialog-close]="value">Set</button>
</div>
