<h2 mat-dialog-title>
  Notify Email
</h2>

<div mat-dialog-content>

  <dx-validation-group #targetGroup>
    <div class="dx-fieldset">
      <!--    <div class="dx-fieldset-header">Personal Info</div>-->

      <label>To</label>
      <dx-select-box
        [dataSource]="emails"
        [(value)]="to"
        [acceptCustomValue]="true"
      >
        <dx-validator name="Email">
          <dxi-validation-rule type="required"></dxi-validation-rule>
          <dxi-validation-rule type="email" message="Email is invalid"></dxi-validation-rule>
        </dx-validator>
      </dx-select-box>

      <br />

      <label>Subject</label>
      <dx-text-box [(value)]="subject"></dx-text-box>

      <br />

      <label>Body</label>
      <dx-text-area
        [(value)]="body"
        [height]="200"
        [valueChangeEvent]='"keyup"'
      ></dx-text-area>

      <br />

      <dx-validation-summary></dx-validation-summary>

    </div>
  </dx-validation-group>

</div>

<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="false">Cancel</button>
  <button mat-button color="primary" (click)="submit()">Submit</button>
</div>
