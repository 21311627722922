import { Component, ViewChild } from '@angular/core';
import { DxPivotGridComponent } from 'devextreme-angular/ui/pivot-grid';
import DevExpress from 'devextreme/bundles/dx.all';
import moment from 'moment';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { LoggerService } from '../../../../shared/sdk';
import PivotGridDataSourceOptions = DevExpress.data.PivotGridDataSourceOptions;

@Component({
  selector: 'app-pivot',
  templateUrl: './pivot.component.html',
  styleUrls: ['./pivot.component.scss'],
})
export class PivotComponent extends ABaseComponent {
  @ViewChild(DxPivotGridComponent, { static: true }) grid: DxPivotGridComponent;

  dataSource: any;
  grid_stateStoring: any;

  showTotalsPrior = false;
  rowsDataFieldArea = false;
  treeHeaderLayout = false;

  showColumnTotals = true;
  showRowTotals = true;
  showColumnGrandTotals = true;
  showRowGrandTotals = true;

  collection = 'BillingPivotData';
  exportFileName = 'Billing_Pivot_' + moment().format('YYYY-MM-DD HH:mm:ss');

  constructor(
    public logger: LoggerService,
    private dss: DataSourceService,
  ) {
    super(logger);

    this.grid_stateStoring = {
      enabled: true,
      type: 'localStorage',
      storageKey: 'cd1bac5a-5278-487e-af1e-f570945ebcac',
    };

    this.dataSource = {
      remoteOperations: true,
      store: this.dss.createMongoStore(this.collection),
      // filter: ['tenant', '<>', 'MD'],
      fields: [
        { dataField: '_id', visible: false },
        { area: 'column', dataField: 'date', dataType: 'date', groupInterval: 'year' },
        { area: 'column', dataField: 'date', dataType: 'date', groupInterval: 'quarter' },
        { area: 'column', dataField: 'date', dataType: 'date', groupInterval: 'month' },
        { dataField: 'date', dataType: 'date', groupInterval: 'day' },

        { dataField: 'serviceType', dataType: 'string', caption: 'Service Type', isMeasure: false, area: 'row' },
        { dataField: 'broker', dataType: 'string', caption: 'Broker', isMeasure: false, area: 'row' },
        { dataField: 'tenant', dataType: 'string', caption: 'Tenant', isMeasure: false, area: 'row' },
        { dataField: 'validationState', dataType: 'string', caption: 'V8n State', isMeasure: false, area: 'row' },

        ...[
          { dataField: 'signsTotal', caption: 'Trips Conducted', area: 'data' },
          { dataField: 'signsWithTripID', caption: 'Issued Trip IDs', area: 'data' },
          { dataField: 'signsWithUniqTripID', caption: 'Issued Trip IDs (uniq in subset)', area: 'data' },
          { dataField: 'signsHasClaim', caption: 'Trips Billed', area: 'data' },
          { dataField: 'signsWoClaim', caption: 'Trips UnBilled', area: 'data' },

          { dataField: 'claimsTotalBilled', caption: 'Claims Total Billed', area: 'data' },
          { dataField: 'claimsPending', caption: 'Claims Pending', area: 'data' },
          { dataField: 'claimsPaid', caption: 'Claims Paid', area: 'data' },
          { dataField: 'claimsDenied', caption: 'Claims Denied', area: 'data' },
          { dataField: 'claimsCancelled', caption: 'Claims Cancelled', area: 'data' },
        ].map(i => ({
          ...i,
          dataType: 'number',
          summaryType: 'sum',
          isMeasure: true,
        })),

        //

        ...[
          { dataField: 'claimsTotalBilledValue', caption: '$ Claims Total Billed', area: 'data' },
          { dataField: 'claimsPendingValue', caption: '$ Claims Pending', area: 'data' },
          { dataField: 'claimsPaidValue', caption: '$ Claims Paid', area: 'data' },
          { dataField: 'claimsDeniedValue', caption: '$ Claims Denied', area: 'data' },
          { dataField: 'claimsCancelledValue', caption: '$ Claims Cancelled', area: 'data' },
        ].map(i => ({
          ...i,
          dataType: 'number',
          summaryType: 'sum',
          format: 'currency',
          isMeasure: true,
        })),
      ],
    } as PivotGridDataSourceOptions;
  }
}
