import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { select } from '@ngrx/store';
import DevExpress from 'devextreme/bundles/dx.all';
import CustomStore from 'devextreme/data/custom_store';
import groupBy from 'lodash-es/groupBy';
import head from 'lodash-es/head';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CommonService } from '../../../../shared/modules/my-common/services/common.service';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { Facility, FacilityView } from '../../../../shared/sdk';
import { SetTenant } from '../../../../store/actions/sign';
import { getTenantPending, getTenants } from '../../../../store/reducers/sign';
import DataSourceOptions = DevExpress.data.DataSourceOptions;

@Component({
  selector: 'app-select-tenant-dialog',
  templateUrl: './select-tenant-dialog.component.html',
  styleUrls: ['./select-tenant-dialog.component.scss'],
})
export class SelectTenantDialogComponent implements OnInit {
  dso$: Observable<DataSourceOptions>;

  isSetTenantPending$: Observable<boolean>;
  userTenants$: Observable<number[]>;
  isSU$: Observable<boolean>;
  grouped = true;

  constructor(
    private dialogRef: MatDialogRef<SelectTenantDialogComponent, any>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private router: Router,
    private common: CommonService,
    private config: ConfigService,
    private dss: DataSourceService,
  ) {
    this.userTenants$ = this.common.store.pipe(select(getTenants));
    this.isSU$ = this.config.isSU$;
    this.isSetTenantPending$ = this.common.store.pipe(select(getTenantPending));

    this.dso$ = combineLatest([this.userTenants$, this.isSU$]).pipe(
      map(([fIds, _isSU]) => {
        const so = this.dss.getStoreOptions(FacilityView, undefined, false);
        so.customFilter = {
          where: { name: { neq: 'N/A' }, ...(_isSU ? {} : { id: { inq: fIds } }) },
          order: ['typeOrder DESC', 'type', 'name'],
        };

        return {
          store: new CustomStore(so),
          paginate: false,
          postProcess: this.grouped
            ? (recs: any[]) => {
                recs = Object.entries(groupBy(recs, 'type')) //
                  .map(([key, items]) => ({ key, items }));
                return recs;
              }
            : undefined,
        } as DataSourceOptions;
      }),
    );
  }

  ngOnInit() {}

  list_onSelectionChanged(e) {
    const item: Facility = head<Facility>(e.addedItems);
    this.router.navigate(['/loading']).then(() => {
      this.common.store.dispatch(new SetTenant(item.id));
    });
    this.dialogRef.close(item.id);
  }

  skip_click() {
    this.router.navigate(['/loading']).then(() => {
      this.common.store.dispatch(new SetTenant(-1));
    });
    this.dialogRef.close(0);
  }

  cancel_click() {
    this.dialogRef.close();
  }
}
