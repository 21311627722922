import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DxValidationGroupComponent } from 'devextreme-angular/ui/validation-group';
import compact from 'lodash-es/compact';
import uniqBy from 'lodash-es/uniqBy';
import { utc } from 'moment/moment';
import { oc } from 'ts-optchain';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { Consumer, LoggerService } from '../../../../shared/sdk';

@Component({
  selector: 'app-dlg-notify-coord',
  templateUrl: './dlg-notify-coord.component.html',
  styleUrls: ['./dlg-notify-coord.component.scss'],
})
export class DlgNotifyCoordComponent extends ABaseComponent implements OnInit {
  @ViewChild('targetGroup', { static: false }) validationGroup: DxValidationGroupComponent;

  emails = uniqBy(
    compact<string>([
      oc(this.auth).ServiceCoordinatorEmail(), //
      oc(this.client).cwEmail(), //
    ]),
    v => v.toLowerCase(),
  );

  to: string;
  subject = 'Request for Renewed PERS Service Authorization';
  body =
    `Dear ${this.coordinatorName}, \n\n` +
    'I`m reaching out to notify you that the PERS Service Authorization for the participant listed ' +
    'below will expire on [date].\n' +
    'Could you please provide a renewed Service Authorization for this member or let us know ' +
    'how to proceed? \n' +
    `Participant Name: ${this.clientName} \n` +
    `Date of Birth: ${utc(this.clientDob).format('L')} \n` +
    `MCI Number: ${this.clientMci} \n\n` +
    'Thank you.';

  get auth() {
    return oc(this.data).cellInfo.data.last.__lastCodeAuth.last();
  }

  get client() {
    return oc(this.data).cellInfo.data.last() as Consumer;
  }

  get coordinatorName() {
    return oc(this.auth).ServiceCoordinatorName() || oc(this.client).cwName();
  }

  get clientName() {
    return oc(this.auth).Name() || [oc(this.client).person.firstname(), oc(this.client).person.lastname()].join(' ');
  }

  get clientDob() {
    return oc(this.auth).MemberDOB() || oc(this.client).person.dob();
  }

  get clientMci() {
    return oc(this.auth).MedicaidID() || oc(this.client).mci();
  }

  constructor(
    protected logger: LoggerService,
    private dialogRef: MatDialogRef<DlgNotifyCoordComponent, any>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      cellInfo: any;
    },
  ) {
    super(logger);
  }

  ngOnInit() {}

  submit() {
    const result = this.validationGroup.instance.validate();
    if (!result.isValid) return;

    alert('not implemented');
    this.dialogRef.close();
  }
}
