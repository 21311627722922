import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import DevExpress from 'devextreme/bundles/dx.all';
import notify from 'devextreme/ui/notify';
import groupBy from 'lodash-es/groupBy';
import { Facility, FacilityView } from '../../../../shared/sdk';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import head from 'lodash-es/head';
import CustomStore from 'devextreme/data/custom_store';
import DataSourceOptions = DevExpress.data.DataSourceOptions;

@Component({
  selector: 'app-select-tenant-popup',
  templateUrl: './select-tenant-popup.component.html',
  styleUrls: ['./select-tenant-popup.component.scss'],
})
export class SelectTenantPopupComponent implements OnInit {
  constructor(private dss: DataSourceService) {}

  dso: DataSourceOptions;
  grouped = true;
  @Input() popupVisible = true;

  @Output() mySetTenant: EventEmitter<number> = new EventEmitter<number>();
  @Output() myCancel: EventEmitter<void> = new EventEmitter<void>();

  private _isPending = false;
  get isPending(): boolean {
    return this._isPending;
  }

  @Input()
  set isPending(value) {
    this._isPending = value;
  }

  private _error: any = null;
  get error(): string {
    return (this._error && this._error.message) || this._error;
  }

  @Input()
  set error(value) {
    this._error = value;
    this.error && notify(this.error, 'error', 5000);
  }

  private _ids: number[] = [];
  get ids(): number[] {
    return this._ids;
  }

  @Input()
  set ids(value: number[]) {
    this._ids = value;

    this.dso = this.buildDataSource();
  }

  private _isSU = false;
  get isSU(): boolean {
    return this._isSU;
  }

  @Input()
  set isSU(value: boolean) {
    this._isSU = value;

    this.dso = this.buildDataSource();
  }

  skip_onClick = () => {
    this.mySetTenant.emit(-1);
  };

  cancel_onClick = () => {
    this.myCancel.emit();
  };

  ngOnInit() {}

  list_onSelectionChanged(e) {
    // console.log(e);
    const item: Facility = head<Facility>(e.addedItems);
    if (item) {
      this.mySetTenant.emit(item.id);
    }
  }

  private buildDataSource() {
    const so = this.dss.getStoreOptions(FacilityView, undefined, false);
    so.customFilter = {
      where: { name: { neq: 'N/A' }, ...(this.isSU ? {} : { id: { inq: this.ids } }) },
      order: ['typeOrder DESC', 'type', 'name'],
    };

    return {
      store: new CustomStore(so),
      paginate: false,
      postProcess: this.grouped
        ? (recs: any[]) => {
            recs = Object.entries(groupBy(recs, 'type')) //
              .map(([key, items]) => ({ key, items }));
            return recs;
          }
        : undefined,
    } as DataSourceOptions;
  }
}
