import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DxTagBoxComponent } from 'devextreme-angular/ui/tag-box';
import CustomStore from 'devextreme/data/custom_store';
import { DataSourceOptions } from 'devextreme/data/data_source';
import notify from 'devextreme/ui/notify';
import { compact, intersection } from 'lodash-es';
import groupBy from 'lodash-es/groupBy';
import { combineLatest, of, Subject } from 'rxjs';
import { catchError, map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { oc } from 'ts-optchain';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import {
  AssignedToFacility,
  AssignedToFacilityApi,
  Employee,
  EmployeeApi,
  Facility,
  LoggerService,
  MyUserApi,
} from '../../../../shared/sdk';

@Component({
  selector: 'app-dlg-assign-facility',
  templateUrl: './dlg-assign-facility.component.html',
  styleUrls: ['./dlg-assign-facility.component.scss'],
})
export class DlgAssignFacilityComponent extends ABaseComponent implements OnInit {
  tagBoxDisabled = false;

  facilityDso: DataSourceOptions = [];
  $reloadAssigned$: Subject<any> = new Subject();
  assignedFacilities: number[] = [];

  employee: Employee | undefined;

  @ViewChild('facilityTagBox', { static: false }) tagBox: DxTagBoxComponent;

  get employeeId(): number {
    return oc(this.data).model.id();
  }

  constructor(
    protected logger: LoggerService,
    private dialogRef: MatDialogRef<DlgAssignFacilityComponent, any>,
    @Inject(MAT_DIALOG_DATA)
    private data: {
      model: Employee;
      // userId: number;
    },
    private dss: DataSourceService,
    protected ui: UiService,
    protected userApi: MyUserApi,
    protected employeeApi: EmployeeApi,
    protected assignedToFacilityApi: AssignedToFacilityApi,
  ) {
    super(logger);

    this.facilityDso = this.buildFacilityDataSource();
  }

  ngOnInit() {
    super.ngOnInit();

    this.$reloadAssigned$
      .pipe(
        startWith(true),
        tap(() => (this.tagBoxDisabled = true)),

        switchMap(() =>
          this.employeeApi.findById<Employee>(this.employeeId, {
            fields: { userId: true, tenantIds: true },
          }),
        ),

        tap(e => (this.employee = e)),

        switchMap(e =>
          combineLatest([
            of(e),

            this.assignedToFacilityApi
              .find<AssignedToFacility>({
                where: { employeeId: e.id },
                fields: { facilityId: true },
              })
              .pipe(map(recs => recs.map(r => r.facilityId))),

            this.userApi
              .getRoles(e.userId)
              .pipe(map((roles: string[]) => roles.filter(r => r === 'DRIVER' || r.startsWith('DRIVER:')))),
          ]),
        ),
        map(([e, ids2, driverRoles]) => {
          const ids1 = e.tenantIds as number[];
          const rolesFIds = compact(driverRoles.map(r => r.split(':')[1])).map(Number);

          if (driverRoles.length === 1 && driverRoles[0] === 'DRIVER') {
            return intersection(ids1, ids2);
          } else if (rolesFIds.length > 0) {
            return rolesFIds;
          } else {
            return [];
          }
        }),
        tap(ids => (this.assignedFacilities = ids)),
        catchError(err => of(null)),
        tap(() => setTimeout(() => (this.tagBoxDisabled = false), 0)),
        takeUntil(this.$onDestroy$),
      )
      .subscribe();
  }

  private buildFacilityDataSource() {
    const so = this.dss.getStoreOptions(Facility, undefined, false);
    so.customFilter = {
      where: { type: { inq: ['BASE', 'ADC', 'MEALS'] }, name: { neq: 'N/A' } },
      order: ['typeOrder DESC', 'type', 'name'],
    };

    const dso = {
      store: new CustomStore(so),
      postProcess(recs: any[]) {
        recs = Object.entries(groupBy(recs, 'type')) //
          .map(([key, items]) => ({ key, items }));
        return recs;
      },
    } as DataSourceOptions;

    return dso;
  }

  tagBox_onInitialized(e: { component: DxTagBoxComponent }) {}

  resetFacilities() {
    this.$reloadAssigned$.next(true);
  }

  assignFacilities() {
    const assignedIds: number[] = this.tagBox.value;

    void of(true)
      .pipe(
        tap(() => this.ui.showLoading()),
        switchMap(() => this.employeeApi.assignDriver(this.employeeId, assignedIds)),
        // switchMap(() => this.employeeApi.visibleFromFacilities(this.employee.id, assignedIds)),
        // switchMap(() => this.employeeApi.assignToFacilities(this.employee.id, assignedIds)),
        // switchMap(() => {
        //   return this.userApi.getRoles(this.userId).pipe(
        //     map((roles: string[]) => roles.filter(r => r === 'DRIVER' || r.startsWith('DRIVER:'))),
        //     switchMap(roles => this.userApi.unmapFromRoles(this.userId, roles)),
        //     switchMap(() =>
        //       this.userApi.mapToRoles(
        //         this.userId,
        //         assignedIds.map(fId => 'DRIVER:' + fId),
        //       ),
        //     ),
        //   );
        // }),
        tap(() => notify('Done!', 'success', 5000)),
        // tap(() => this.dialogRef.close(true)),
        catchError(err => of(notify(err.message, 'error', 5000))),
        tap(() => this.$reloadAssigned$.next(true)),
        tap(() => this.ui.hideLoading()),
      )
      .toPromise();
  }

  fDisplayExpr = (f: Facility): string => {
    return `${f.shortname} [${f.type}]`;
  };
}
